// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  home: getIcon('ic_banking'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.home },
/*      { title: 'Employee Dashboard', path: PATH_DASHBOARD.general.employeeDashboard, icon: ICONS.home },
      { title: 'Admin Dashboard', path: PATH_DASHBOARD.general.adminDashboard, icon: ICONS.home },*/
      { title: 'Store', path: PATH_DASHBOARD.general.store, icon: ICONS.ecommerce },
      { title: 'Employee', path: PATH_DASHBOARD.user.root, icon: ICONS.user }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Payroll',
    items: [
      { title: 'Timekeeping Payroll', path: PATH_DASHBOARD.general.report, icon: ICONS.analytics },
      { title: 'Manual Payroll', path: PATH_DASHBOARD.general.payroll, icon: ICONS.dashboard }
      //   {
      //     title: 'user',
      //     path: PATH_DASHBOARD.app.root,
      //     icon: ICONS.user,
      //     children: [
      //       { title: 'Five', path: PATH_DASHBOARD.app.pageFive },
      //       { title: 'Six', path: PATH_DASHBOARD.app.pageSix }
      //     ]
      //   },
    ]
  }
];

export default sidebarConfig;
