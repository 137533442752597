import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

const key = 'token';
const user = 'user';
const uid = 'uid';

const store_sign_in = (data) => {
  try {
    ls.set(user, data);
    ls.set(key, data.accesstoken);
  } catch (error) {
    console.log('Error storing the auth token', error);
  }
};

const store_user = (user) => {
  try {
    ls.set('user', user);
  } catch (error) {
    console.log('Error storing the user details', error);
  }
};

const store_token = (token) => {
  try {
    ls.set(key, token);
  } catch (error) {
    console.log('Error storing the auth token', error);
  }
};

const get_token = () => {
  try {
    return ls.get(key);
  } catch (error) {
    console.log('Error getting the auth token', error);
  }
};

const get_user = async () => {
  try {
    return ls.get(user);
  } catch (error) {
    console.log('Error getting the user', error);
    return false;
  }
};

const remove = () => {
  try {
    ls.remove(key);
    ls.remove(user);
    ls.remove(uid);
  } catch (error) {
    console.log('Error removing the token', error);
  }
};

const exp_obj = {
  get_token,
  get_user,
  store_token,
  store_user,
  store_sign_in,
  remove
};

export default exp_obj;
