// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    employeeDashboard: path(ROOTS_DASHBOARD, '/employee/dashboard'),
    adminDashboard: path(ROOTS_DASHBOARD, '/admin/dashboard'),
    store: path(ROOTS_DASHBOARD, '/store/list'),
    employee: path(ROOTS_DASHBOARD, '/users/list'),
    report: path(ROOTS_DASHBOARD, '/payroll'),
    payroll: path(ROOTS_DASHBOARD, '/payroll-v2')
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
    pageFour: path(ROOTS_DASHBOARD, '/app/four'),
    pageFive: path(ROOTS_DASHBOARD, '/app/five'),
    pageSix: path(ROOTS_DASHBOARD, '/app/six')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users/list'),
    create: path(ROOTS_DASHBOARD, '/users/create')
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store/list'),
    create: path(ROOTS_DASHBOARD, '/store/create'),
    view: path(ROOTS_DASHBOARD, '/store/:id')
  }
};
