import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// material
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import storage from 'utils/storage';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigation = useNavigate();
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const token = storage.get_token();
  const check_login = async () => {
    const token = await storage.get_token()
    const current_date = new Date()
    if (!token) {
      await storage.remove()
      return navigation('/login')
    }

    if (jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
      await storage.remove()
      return navigation('/login')
    }

  }

  useEffect(() => {
    check_login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RootStyle>
      {token !== '' ? (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <Outlet />
          </MainStyle>
        </>
      ) : (
        window.location.replace('/login')
      )}
    </RootStyle>
  );
}
