import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const EmployeeDashboard = Loadable(lazy(() => import('../pages/EmployeeDashboard')));
//const AdminDashboard = Loadable(lazy(() => import('../pages/AdminDashboard')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const ListEmployee = Loadable(lazy(() => import('../pages/UserList')));
const CreateEmployee = Loadable(lazy(() => import('../pages/CreateEmployee')));

const ListStore = Loadable(lazy(() => import('../pages/StoreList')));
const CreateStore = Loadable(lazy(() => import('../pages/CreateStore')));
const ViewStore = Loadable(lazy(() => import('../pages/StoreDetails')));
const Reports = Loadable(lazy(() => import('../pages/Payroll')));
const ReportsV2 = Loadable(lazy(() => import('../pages/Payrollv2')));

// Main
const Login = Loadable(lazy(() => import('../pages/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));
// const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));

const Router = () => {
  return useRoutes([
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'employee/dashboard', element: <EmployeeDashboard /> },
        //{ path: 'admin/dashboard', element: <AdminDashboard /> },
        { path: 'users/list', element: <ListEmployee /> },
        { path: 'users/create', element: <CreateEmployee /> },
        { path: 'store/list', element: <ListStore /> },
        { path: 'store/create', element: <CreateStore /> },
        { path: 'store/:id', element: <ViewStore /> },
        { path: 'payroll', element: <Reports /> },
        { path: 'payroll-v2', element: <ReportsV2 /> },
        { path: 'three', element: <PageThree /> }
        // { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '404', element: <NotFound /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
};

export default Router;
